import ContractService from "../services/contract.service";

export const contract = {
  namespaced: true,

  state:{
    contractTemplates: null,
    targetContract: null

  },
  getters:{

  },
  mutations:{
    SET_CONTRACT_TEMPLATES(state,data){
        state.contractTemplates = data
    },
    SET_TARGET_CONTRACT(state, data){
        state.targetContract = data
    }

  },
  actions:{
    getContractTemplates({commit}){
        ContractService.getcontractTemplates().then((response) =>{
            commit("SET_CONTRACT_TEMPLATES", response.data.ContractTemplates)
    })
        
    },
   

  }

}
