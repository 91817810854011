import Vue from 'vue'
import App from './App.vue'
import Vuelidate from 'vuelidate'
import router from './router/index'
import store from './store'
import vco from "v-click-outside"
import "@/design/index.scss";


require("./store/subscriber")

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(BootstrapVue);

Vue.config.productionTip = false
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use(require('vue-moment'));
Vue.use(vco)

store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

})

