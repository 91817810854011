<script>


export default {
  name: "app",
  
};
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
