import QuestService from "../services/quest.service";

export const quest = {

    namespaced: true,

    state:{
        questTemplates: null

    },
    getters:{

    },
    mutations:{
        SET_QUEST_TEMPLATES(state,data){
            state.questTemplates = data
        },

    },
    actions:{
        
    }
}