import axios from "axios";
import appConfig from "../../app.config.json";

const API_URL = appConfig.API_URL;

class QuestService {

    
    newQuestTemplate(data){
        return axios.post(API_URL + "newquesttemplate", data);
    }
    updateQuestTemplate(data){
        console.log(data)

        return axios.post(API_URL + "updatequesttemplate", data);
    }
    
    getQuestTemplates(){
        return axios.post(API_URL + "getqueststemplates");
    }
    deleteTemplate(data){
        console.log(data)
        return axios.post(API_URL + "deletetemplate", data);
    }

}

export default new QuestService();