import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

import { auth } from './auth.module';
import { user } from './user.module';
import { contract } from './contract.module';
import { quest } from './quest.module';


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    contract,
    quest
  },
  plugins: [createPersistedState()]
});
