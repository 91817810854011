import UserService from '../services/user.service';

// import axios from 'axios'

export const user ={
    namespaced: true,

    state:{
        users: null,
        targetuser:null

    },
    getters:{

    },
    mutations:{
       SET_USERS(state, data) {
        state.users = data
       },
       SET_TARGETUSER(state, data){
        state.targetuser = data
       }

    },
    actions:{

        getUsers({commit}){
            return UserService.getusers().then(
                response => {
                    commit('SET_USERS', response.data)
                }
            )
        },
        update({commit}, user){
            console.log(user)
            return UserService.updateUser(user).then(
                response => {
                    console.log(response.data)
                    commit('SET_TARGETUSER', response.data.user)
                    
                    UserService.getusers().then(
                        res => {
                            commit('SET_USERS', res.data)
                        }
                    )
                }
            )
        },
        resetPass(_,resetdata){
            return UserService.resetpass(resetdata).then(
                response => {

                    return Promise.resolve(response.data)
                }
            )
        },
        avatar({commit}, file){
            return UserService.uploadAvatar(file).then(
                response => {
                    commit('SET_TARGETUSER', response.data.user)
                }
            )
        }

    },
    

}