import axios from 'axios';
import appConfig from "../../app.config.json";


const API_AUTH_URL = appConfig.API_AUTH_URL; 


class AuthService {


    register ( user ) {
      return axios.post(API_AUTH_URL + 'register', user)       
    }

    signIn ( credentials ) {
        return axios.post(API_AUTH_URL + 'login', credentials)
    }

    getme () {
        return axios.get(API_AUTH_URL + 'getme')
    }

    signOut () {
        return axios.post(API_AUTH_URL + 'logout')
    }

    forgot (data) {
        return axios.post(API_AUTH_URL + 'sendPasswordResetLink', data)
    }

    resetpass(data){
        return axios.post(API_AUTH_URL + 'resetPassword', data)
    }





}

export default new AuthService();