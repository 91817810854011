import axios from 'axios';

import appConfig from "../../app.config.json";

const API_URL = appConfig.API_URL; 

class UserService {

    getusers(){
        return axios.get(API_URL + 'users')
    }
    updateUser(user){
        return axios.post(API_URL + 'update', user)
    }
    resetpass(data){
        return axios.post(API_URL + 'resetpass', data)
    }
    uploadAvatar(file){
        return axios.post(API_URL + 'avatar', file)
    }


}

export default new UserService();