import AuthService from "../services/auth.service";

export const auth = {
  namespaced: true,

  state: {
    token: null,
    user: null,
  },

  getters: {
    user(state) {
      return state.user;
    },
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, data) {
      state.user = data;
    },
  },

  actions: {
    register(_, user) {
      return AuthService.register(user).then((response) => {
        return Promise.resolve(response.data);
      });
    },

    



    async signIn ({ dispatch }, credentials) {
        let response = await AuthService.signIn(credentials)

        return dispatch('attempt', response.data.token)
    },
    async attempt ({ commit, state }, token) {

        if ( token ) {
            commit('SET_TOKEN', token)
        }
        if(!state.token){
            return
        }
        try {
            let response = await AuthService.getme()

                commit ('SET_USER', response.data)
            } catch (e) {
                    commit('SET_TOKEN', null)
                    commit('SET_USER', null)
                }

    },
    checkUser({ commit }) {
      return AuthService.getme()
      .then((response) => {

        commit("SET_USER", response.data);
      });
    },

    signOut({ commit }) {
      return AuthService.signOut().then(() => {
        commit("SET_TOKEN", null);
        commit("SET_USER", null);
        commit("SET_AUTHENTICATED", false);
        commit("user/SET_TARGETUSER", null, { root: true });
        commit("user/SET_USERS", null, { root: true });
        commit("email/SET_INBOX", null, { root: true });
        commit("email/SET_TARGETEMAIL", null, { root: true });
      });
    },

    async forgot(email) {
      let response = await AuthService.forgot(email);
      console.log(response);
    },

    resetPassword(_, payload) {
      return AuthService.resetpass(payload).then((response) => {
        return Promise.resolve(response.data);
      });
    },
  },
};
