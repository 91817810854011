import axios from "axios";
import appConfig from "../../app.config.json";

const API_URL = appConfig.API_URL;

class ContractService {
  newContractTemplate(data) {
    return axios.post(API_URL + "newcontracttemplate", data);
  }
  updateContractTemplate(data) {
    return axios.post(API_URL + "updatecontracttemplate", data);
  }
  getcontractTemplates(){
    return axios.post(API_URL + "getcontemplates");
  }
  getMyContracts(){
    return axios.post(API_URL + "getmycontracts");
  }
  deleteContractTemplate(data){
    return axios.post(API_URL + "deletecontracttemplate", data);
  }

 

}

export default new ContractService();
