import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView'),
    beforeEnter: (to, from, next) => {
      const role = store.getters['auth/user'].role
      console.log("from router HOME", role);
      
      if (role === 'Admin'){return next({name: 'adminDash'})}
      else if(role === 'Sales') {return next({name: 'salesDash'})}
      // else if(role == 'Manager') {return next({name: 'AccManagaerDash'})}
      // else if(role == 'Director') {return next({name: 'SalesDirDash'})}
      else if(role == 'Candidate') {return next({name: 'candidateDash'})}
      
      else next()
    }
  },
  {
    path: '/admindash',
    name: 'adminDash',
    component: () => import('@/views/AdminViews/AdminDash'),
        beforeEnter: (to, from, next) => {                    
        if (store.getters['auth/user'].role != 'Admin' ) {
            return next({
                name: 'home'
            })
        }
        else next()
    }
  },
  {
    path: '/salesdash',
    name: 'salesDash',
    component: () => import('@/views/SalesViews/SalesDash'),
        beforeEnter: (to, from, next) => {
        if (store.getters['auth/user'].role != 'Sales' ) { 
            return next({
                name: 'home'
            })
        }
        else next()
    }
  },
  {
    path: '/candidatedash',
    name: 'candidateDash',
    component: () => import( '../views/CandidateViews/CandidateDash' ),
        beforeEnter: (to, from, next) => {                    
        if (store.getters['auth/user'].role != 'Candidate' ) {
            return next({
                name: 'home'
            })
        }
        else next()
    }
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import('@/views/Templates')
  },

  {
    path: '/portal',
    name: 'portal',
    component: () => import('@/views/Portal')
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login')
  },
  {
    path: '/userprofile',
    name: 'userprofile',
    component: () => import('../views/UserProfile')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/ForgotPassword')
  },
  {
    path: '/tempreg',
    name: 'tempreg',
    component: () => import('../views/tempRegister.vue'),
    
  },
 

]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach ((to, from, next) => {
  const publicPages = [ '/login', '/adminuser', '/reset-password', '/reset-password/:token',  '/tempreg'];
  if (!store.getters['auth/user'] && !publicPages.includes(to.path)) {
      return next({
          name: 'login'
      })}else next()
});

export default router
